import React from "react";
import ReactDOM from "react-dom";
import { useState, useEffect, Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

import Terms from "./Terms";
import About from "./About";

import "./App.css";

const stripePromise = loadStripe("pk_test_8eZ6l4ll3qWgUHcgE3D38C9Q");

function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route path="/stripe">
            <Stripe />
          </Route>
          <Route path="/😎">
            <Emoji />
          </Route>

          <Route path="/success">
            <Success />
          </Route>

          <Route path="/terms">
            <AppTerms />
          </Route>

          <Route path="/about">
            <AboutPage />
          </Route>

          <Route path="/cancel">
            <Cancel />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

function Stripe() {
  return (
    <div class="center">
      <h2>Loading Stripe</h2>
    </div>
  );
}

function AppTerms() {
  return (
    <div>
      <Menu />
      <Terms />
      <Footer />
    </div>
  );
}

function AboutPage() {
  return (
    <div>
      <Menu />
      <About />
      <Footer />
    </div>
  );
}

function Emoji() {
  return (
    <div class="center">
      <h1>😎</h1>
      <h2>Stay cool.</h2>
    </div>
  );
}

function Success() {
  return (
    <div class="center">
      <h2>Success</h2>
    </div>
  );
}

function Cancel() {
  return (
    <div class="center">
      <h2>Order canceled</h2>
    </div>
  );
}

function Home() {
  var name = window.location.href.replace("//", "").split("/").reverse()[0];

  if (name) {
    return <Userpage username={name} />;
  } else {
    return <Mainpage />;
  }
}

function Mainpage() {
  return (
    <div>
      <Menu />
      <div className="main">
        <div className="main-text">
          <h1>Welcome to Megaphone</h1>
          <h2>
            Send SMS messages, push notifications and email to your friends and
            fans at the push of a button{" "}
          </h2>
        </div>
      </div>
      <Footer />
    </div>
  );
}

function Menu() {
  return (
    <div className="menu">
      <a className="title-link" href="/">
        megaphone
      </a>
    </div>
  );
}

function Footer() {
  return (
    <div className="footer">
      <p>
        <a className="url-link" href="/about">
          About
        </a>
      </p>

      <p>
        <a className="url-link" href="/terms">
          Terms
        </a>
      </p>
    </div>
  );
}

function ProductShot({ photo }) {
  return (
    <img
      src={
        "https://firebasestorage.googleapis.com/v0/b/megaphone-71290.appspot.com/o/public%2Fmegaphone_image.jpg?alt=media&token=dbdd600a-d180-4c10-a387-02c2b5008a3a"
      }
      alt="Megaphone"
      className="product-shot"
    ></img>
  );
}

function Userpage({ username }) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [userinfo, setUserinfo] = useState();
  const [message, setMessage] = useState();

  useEffect(() => {
    setTimeout(showMessage, 5000);
    function showMessage() {
      setMessage("Sorry, user not found 😕");
    }

    var data = { username: username };
    fetch(
      "https://us-central1-megaphone-71290.cloudfunctions.net/widgets/userpage",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          setIsLoaded(true);
          setUserinfo(result);
        },
        (error) => {
          setIsLoaded(true);
          setUserinfo(error);
        }
      );
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return (
      <div className="center">
        {message != undefined ? <h2>{message}</h2> : <LoadingRipple />}
      </div>
    );
  } else {
    return (
      <div>
        <Menu />

        <div>
          <div className="userpage">
            {userinfo && userinfo["photoURL"] && (
              <Avatar photo={userinfo["photoURL"]} />
            )}

            {userinfo && userinfo["username"] && (
              <div>
                <h1> @{userinfo["username"]}</h1>

                <FollowSMS
                  username={userinfo["username"]}
                  sms={userinfo["sms"]}
                />
              </div>
            )}

            <br></br>
            <br></br>
            {true ? <div></div> : <GetContactInfo />}
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

function LoadingRipple() {
  return (
    <div className="lds-ripple">
      <div></div>
      <div></div>
    </div>
  );
}

function FollowSMS({ username, sms }) {
  const SMSbutton = () => {
    console.log("Click");
    window.location.href = "sms://+12023350504&body=@Andrew";
  };

  return (
    <div>
      
     Text @{username} to <b>{sms}</b> to get SMS updates from me
    
    </div>
  );
}

function GetMegaphone() {
  function buttonClick() {}

  return (
    <div>
      <button className="get-button">Get Megaphone</button>
    </div>
  );
}

function Avatar({ photo }) {
  return <img src={photo} alt="Avatar" className="avatar"></img>;
}

function GetContactInfo() {
  const handleClick = async (event) => {
    console.log("Click!");
  };

  return (
    <div>
      <input className="form-input spaced" placeholder="email"></input>

      <input className="form-input spaced" placeholder="phone"></input>

      <button className="follow-button" onClick={handleClick}>
        Submit
      </button>
    </div>
  );
}

function Checkout() {
  const handleClick = async (event) => {
    // Get Stripe.js instance
    const stripe = await stripePromise;

    // Call your backend to create the Checkout Session
    const response = await fetch(
      "https://us-central1-megaphone-71290.cloudfunctions.net/widgets/create-checkout-session",
      { method: "POST" }
    );

    const session = await response.json();

    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (result.error) {
      console.log(result.error.message);
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    }
  };
  return (
    <button type="button" role="link" onClick={handleClick}>
      Checkout
    </button>
  );
}

export default App;
