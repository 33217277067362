import React from "react";

function About(){

return(
    <div>
         <div className="page">
             <h2>About</h2>
                <p>
                    Megaphone makes it easy to reach your audience.
                </p>
             </div>
    </div>
)

}

export default About;